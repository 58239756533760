import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/Layout";
import PageBanner from "../components/PageBanner";
import Content, { HTMLContent } from "../components/Content";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

const MaxWidthContainer = styled.div`
  display: grid;
  position: relative;
  background-color: #f5f5f5;
  margin: 0 auto;
  padding: 48px 0;
  grid-template-columns: minmax(24px, auto) minmax(auto, 900px) minmax(
      24px,
      auto
    );
  grid-template-areas:
    ". agent . "
    ". content . ";
  @media only screen and (min-width: 960px) {
    grid-template-columns: minmax(24px, auto) 300px 100px minmax(auto, 900px) minmax(
        24px,
        auto
      );
    grid-template-areas: ". agent . content .";
  }
`;

const Agent = styled(GatsbyImage)`
  grid-area: agent;
  z-index: 2;
  @media only screen and (max-width: 960px) {
    width: 300px;
    margin: 0 auto;
  }
`;

const TeamPageTemplate = ({
  location,
  title,
  content,
  contentComponent,
  agent,
}) => {
  const agentImage = getImage(agent) || agent;
  const PageContent = contentComponent || Content;
  return (
    <>
      <PageBanner location={location} title={title} />
      <MaxWidthContainer>
        <Agent image={agentImage} alt="agent photo" />
        <PageContent
          style={{ gridArea: "content", margin: "auto" }}
          content={content}
        />
      </MaxWidthContainer>
    </>
  );
};

TeamPageTemplate.propTypes = {
  location: PropTypes.object,
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  banner: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

const TeamPage = ({ location, data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout location={location}>
      <TeamPageTemplate
        location={location}
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        agent={post.frontmatter.agent}
        content={post.html}
        banner={post.frontmatter.banner}
      />
    </Layout>
  );
};

TeamPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default TeamPage;

export const TeamPageQuery = graphql`
  query GeneralPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        agent {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 100
              layout: CONSTRAINED
              placeholder: NONE
            )
          }
        }
      }
    }
  }
`;
